import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  Select,
  Divider,
  Skeleton,
  Spin,
} from 'antd';
import 'antd/dist/antd.css';
import { DownloadOutlined } from '@ant-design/icons';
import download from 'downloadjs';
import { convertToPng, replace } from '../../../services/fileUploadService';
import { useMutate, useFetch, handleError } from '../../../hooks/useRequest';
import {
  fetchMutationDnaReportRegisterRequestContent as fetchMutationDnaReportRegisterRequestContentService,
  patchMutationDnaReportRegisterRequest as patchMutationDnaReportRegisterRequestService,
  deleteMutationDnaReportRegisterRequest as deleteMutationDnaReportRegisterRequestService,
} from '../../../services/mutationDnaReportRegisterRequestService';
import * as listMap from '../../../util/listMap';
import DisabledInput from '../../components/disabledInput';

const anchor = document.createElement('a');
const urls = [];
const input = document.createElement('input');
input.accept = 'image/*';
input.type = 'file';

const registerRequestStatusOptions = [
  { key: '확인 중', text: '확인 중', value: '확인 중' },
  { key: '등록 완료', text: '등록 완료', value: '등록 완료' },
  { key: '등록 실패', text: '등록 실패', value: '등록 실패' },
];

const forceDownload = (url) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = () => {
    const extension = xhr.response.type.split('/').pop();

    let downloadUrl = url.split('/').pop();

    if (!downloadUrl.endsWith(extension)) downloadUrl += `.${extension}`;

    download(xhr.response, downloadUrl, xhr.response.type);
  };
  xhr.send();
};

const warnBeforeImageChange = () =>
  window.confirm(
    '원본을 덮어쓰게 되므로 원본 파일 백업 이후 작업하시기 바랍니다.\n계속 진행하시겠습니까?',
  );

const replaceChanged = ({ uploadSuccessDataHistoryId, requestId, values }) => (
  prevContent,
) =>
  prevContent.map((userRequest) =>
    userRequest.id === requestId
      ? {
          ...userRequest,
          photos: userRequest.photos.map((photo) =>
            photo.uploadSuccessDataHistoryId === uploadSuccessDataHistoryId
              ? { ...photo, ...values }
              : photo,
          ),
        }
      : userRequest,
  );

const DnaRegisterHistory = (userId) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [targetId, setTargetId] = useState(null);
  const clearTargetId = useCallback(() => {
    setTargetId(null);
  }, [setTargetId]);

  const [content, setContent] = useState(null);
  const [patchRegisterRequestId, setPatchRegisterRequestId] = useState(null);
  const [deleteRegisterRequestId, setDeleteRegisterRequestId] = useState(null);
  const [registerRequestMemo, setRegisterRequestMemo] = useState({});
  const [registerRequestStatus, setRegisterRequestStatus] = useState({});
  const registerRequestMemoDone = useRef(false);
  const userDataFetchDone = useRef(false);

  const {
    done: fetchMutationDnaReportRegisterRequestContentDone,
    data: fetchMutationDnaReportRegisterRequestContentData,
    call: fetchMutationDnaReportRegisterRequestContent,
  } = useFetch(
    null,
    fetchMutationDnaReportRegisterRequestContentService,
    userId?.userId,
  );

  useEffect(() => {
    if (
      !fetchMutationDnaReportRegisterRequestContentDone &&
      userId &&
      !userDataFetchDone.current
    ) {
      fetchMutationDnaReportRegisterRequestContent();
      userDataFetchDone.current = true;
    }
  }, [
    fetchMutationDnaReportRegisterRequestContentDone,
    userId,
    userDataFetchDone,
  ]);

  useEffect(() => {
    if (fetchMutationDnaReportRegisterRequestContentData)
      setContent(fetchMutationDnaReportRegisterRequestContentData);
  }, [fetchMutationDnaReportRegisterRequestContentData, setContent]);

  const {
    mutate: patchRegisterRequestContent,
    done: isRegisterRequestPatched,
    initialize: initializeRegisterRequestPatch,
  } = useMutate(patchMutationDnaReportRegisterRequestService, userId?.userId, {
    type: fetchMutationDnaReportRegisterRequestContentData?.find(
      (requestContent) => requestContent.id === patchRegisterRequestId,
    )?.type,
    id: patchRegisterRequestId,
    comment: registerRequestMemo[patchRegisterRequestId],
    status: registerRequestStatus[patchRegisterRequestId],
  });

  const {
    mutate: deleteRegisterRequestContent,
    done: isRegisterRequestDeleted,
    initialize: initializeRegisterRequestDelete,
  } = useMutate(
    deleteMutationDnaReportRegisterRequestService,
    userId?.userId,
    deleteRegisterRequestId,
  );

  useEffect(() => {
    if (
      fetchMutationDnaReportRegisterRequestContentData &&
      !registerRequestMemoDone.current
    ) {
      const registerRequestMemoList = {};
      const registerRequestStatusList = {};
      fetchMutationDnaReportRegisterRequestContentData.forEach(
        (registerRequestHistory) => {
          registerRequestMemoList[registerRequestHistory.id] =
            registerRequestHistory.comment;
          registerRequestStatusList[registerRequestHistory.id] =
            registerRequestHistory.status;
        },
      );
      setRegisterRequestStatus(registerRequestStatusList);
      setRegisterRequestMemo(registerRequestMemoList);
      registerRequestMemoDone.current = true;
    }
  }, [fetchMutationDnaReportRegisterRequestContentData]);

  useEffect(() => {
    if (isRegisterRequestPatched) {
      fetchMutationDnaReportRegisterRequestContent();
      initializeRegisterRequestPatch();
      notification.success({
        message: '수정이 완료되었습니다.',
        description: '수정이 완료되었습니다.',
      });
    }
    if (isRegisterRequestDeleted) {
      fetchMutationDnaReportRegisterRequestContent();
      initializeRegisterRequestDelete();
      notification.success({
        message: '삭제가 완료되었습니다.',
        description: '삭제가 완료되었습니다.',
      });
    }
  }, [
    isRegisterRequestPatched,
    isRegisterRequestDeleted,
    fetchMutationDnaReportRegisterRequestContent,
    initializeRegisterRequestPatch,
    initializeRegisterRequestDelete,
  ]);

  useEffect(() => {
    if (deleteRegisterRequestId) {
      deleteRegisterRequestContent();
      setDeleteRegisterRequestId(null);
    }
  }, [deleteRegisterRequestId, deleteRegisterRequestContent]);

  useEffect(() => {
    if (patchRegisterRequestId) {
      patchRegisterRequestContent();
      setPatchRegisterRequestId(null);
    }
  }, [patchRegisterRequestId, patchRegisterRequestContent]);

  const [loading, setLoading] = useState(null);
  useEffect(() => {
    const callback = () => {
      const {
        files: [file],
        dataset: { uploadSuccessDataHistoryId, requestId },
      } = input;

      anchor.download = file?.name;
      const objectUrl = URL.createObjectURL(file);
      urls.push((anchor.href = objectUrl));

      const body = new FormData();
      body.append('image', file);
      setLoading(true);
      replace(uploadSuccessDataHistoryId, body)
        .then(() =>
          setContent(
            replaceChanged({
              uploadSuccessDataHistoryId,
              requestId,
              values: { objectUrl },
            }),
          ),
        )
        .catch(() => alert('이미지 교체에 실패했습니다.'))
        .finally(() => setLoading(false));
    };
    input.addEventListener('change', callback);
    return () => {
      input.removeEventListener('change', callback);
      urls.forEach((url) => URL.revokeObjectURL(url));
      urls.length = 0;
    };
  }, [setContent, setLoading]);

  const handleConvert = useCallback(
    (uploadSuccessDataHistoryId, requestId) => {
      setLoading(true);
      convertToPng(uploadSuccessDataHistoryId)
        .then((signedUrl) =>
          setContent(
            replaceChanged({
              uploadSuccessDataHistoryId,
              requestId,
              values: { signedUrl, objectUrl: signedUrl },
            }),
          ),
        )
        .catch(handleError)
        .finally(() => setLoading(false));
    },
    [setContent, setLoading],
  );

  if (!userDataFetchDone.current) return <Skeleton active />;
  return (
    <>
      {content?.length > 0 ? (
        <>
          {loading && (
            <div className="loadingOverlay">
              <div className="loading">
                <Spin style={{ margin: 'auto' }} />
              </div>
            </div>
          )}
          {content?.map((mutationDnaReportRegisterRequest) => (
            <>
              <Form name="basic" form={form} {...listMap.layout}>
                <Row span={24}>
                  <Col span={12}>
                    <DisabledInput
                      label="신청일"
                      type="createdAt"
                      value={mutationDnaReportRegisterRequest.createdAt}
                    />
                  </Col>
                  <Col span={12}>
                    <DisabledInput
                      label="종류"
                      type="type"
                      value={mutationDnaReportRegisterRequest.type}
                    />
                  </Col>
                </Row>
                <Row span={24}>
                  <Col span={24}>
                    <Form.Item
                      label="사진"
                      labelCol={listMap.largeLayout.labelCol}
                      wrapperCol={listMap.largeLayout.wrapperCol}
                    >
                      {mutationDnaReportRegisterRequest?.photos?.length > 0 ? (
                        mutationDnaReportRegisterRequest?.photos.map(
                          ({
                            signedUrl,
                            objectUrl,
                            uploadSuccessDataHistoryId,
                          }) => (
                            <div
                              style={{
                                display: 'inline-block',
                                border: '1px solid #888',
                                marginRight: 4,
                                borderRadius: 3,
                              }}
                            >
                              <div
                                style={{ height: '50px', textAlign: 'center' }}
                              >
                                <img
                                  src={objectUrl || signedUrl}
                                  style={{ height: '50px' }}
                                />
                              </div>
                              <div style={{ textAlign: 'center' }}>
                                {mutationDnaReportRegisterRequest.createdAt}
                              </div>
                              <div>
                                <Button
                                  type="primary"
                                  icon={<DownloadOutlined />}
                                  onClick={() =>
                                    objectUrl
                                      ? anchor.click()
                                      : forceDownload(signedUrl)
                                  }
                                />
                                <Button
                                  onClick={() => {
                                    if (warnBeforeImageChange()) {
                                      input.dataset.uploadSuccessDataHistoryId = uploadSuccessDataHistoryId;
                                      input.dataset.requestId =
                                        mutationDnaReportRegisterRequest.id;
                                      input.click();
                                    }
                                  }}
                                >
                                  교체
                                </Button>
                                <Button
                                  onClick={() => {
                                    if (warnBeforeImageChange()) {
                                      handleConvert(
                                        uploadSuccessDataHistoryId,
                                        mutationDnaReportRegisterRequest.id,
                                      );
                                    }
                                  }}
                                >
                                  변환
                                </Button>
                              </div>
                            </div>
                          ),
                        )
                      ) : (
                        <h6 className="name-tag redText">사진 없음</h6>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row span={24}>
                  <Col span={24}>
                    <Form.Item
                      label="상담 내역"
                      labelCol={listMap.largeLayout.labelCol}
                      wrapperCol={listMap.largeLayout.wrapperCol}
                    >
                      <Select
                        showSearch
                        allowClear
                        status={
                          registerRequestStatus[
                            mutationDnaReportRegisterRequest.id
                          ] === 'apply' && 'warning'
                        }
                        style={{ width: '100%' }}
                        placeholder="상태를 선택해주세요"
                        value={
                          registerRequestStatus[
                            mutationDnaReportRegisterRequest.id
                          ]
                        }
                        onChange={(_, data) => {
                          if (data?.value !== '등록 실패') {
                            setRegisterRequestMemo({
                              ...registerRequestMemo,
                              [mutationDnaReportRegisterRequest.id]: '',
                            });
                          }
                          setRegisterRequestStatus({
                            ...registerRequestStatus,
                            [mutationDnaReportRegisterRequest.id]: data?.value,
                          });
                        }}
                      >
                        {registerRequestStatusOptions?.map((status) => (
                          <Select.Option
                            id={status?.value}
                            key={status?.value}
                            value={status?.value}
                          >
                            {status?.text}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {registerRequestStatus[
                      mutationDnaReportRegisterRequest.id
                    ] === '등록 실패' && (
                      <Form.Item
                        label="상담 내역"
                        labelCol={listMap.largeLayout.labelCol}
                        wrapperCol={listMap.largeLayout.wrapperCol}
                      >
                        <TextArea
                          rows={4}
                          value={
                            registerRequestMemo[
                              mutationDnaReportRegisterRequest.id
                            ]
                          }
                          onChange={(e) =>
                            setRegisterRequestMemo({
                              ...registerRequestMemo,
                              [mutationDnaReportRegisterRequest.id]:
                                e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    )}
                    <Form.Item
                      wrapperCol={{
                        offset: 4,
                        span: 20,
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: 100 }}
                        onClick={() => {
                          setPatchRegisterRequestId(
                            mutationDnaReportRegisterRequest.id,
                          );
                        }}
                      >
                        저장
                      </Button>
                      <Button
                        danger
                        htmlType="button"
                        style={{ width: 100, marginLeft: 8 }}
                        onClick={() => {
                          if (window.confirm('신청 내역을 삭제하시겠습니까?')) {
                            setDeleteRegisterRequestId(
                              mutationDnaReportRegisterRequest.id,
                            );
                          }
                        }}
                      >
                        삭제
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Divider plain />
            </>
          ))}
        </>
      ) : (
        <div style={{ marginLeft: 10, color: 'red' }}>
          등록 이력이 없습니다.
        </div>
      )}
    </>
  );
};

export default DnaRegisterHistory;
