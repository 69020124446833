import React, { useReducer, useCallback } from 'react';
import {
  notification,
  Form,
  Input,
  Select,
  Radio,
  Checkbox,
  Button,
  Row,
  Col,
} from 'antd';
import { SmileOutlined } from '@ant-design/icons';

const MoveAppUrl = (data) => {
  const initialState = {
    externalUrl: '',
    emailUrl: '',
    emailTypeValue: 'tel',
    rarenoteValue: [],
    reactionStorage: 'rarenote://ReactionStorage?contentsType=treatmentNews',
    treatmentNewsValue: [],
    treatmentNewsTitleTypeValue: 'top',
    treatmentNewsTitleValue: '',
    noticeValue: [],
    dnaValue: [],
    clinicalTrialTitleValue: '',
    togetherTitleValue: '',
    projectValue: [],
    appTypeValue: 'rarenote',
    hasMakeUrl: data?.type === 'entryModal',
    resultUrl: '',
  };
  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });
  const [state, setState] = useReducer(stateReducer, initialState);
  const { Option, OptGroup } = Select;

  const typeOptions = [
    { label: '앱 내 이동', value: 'rarenote' },
    { label: '외부 url 연결', value: 'etc' },
    { label: 'tel, sms, 이메일', value: 'email' },
    { label: '앱 공유', value: 'share' },
  ];

  const emailTypeOptions = [
    { label: 'tel', value: 'tel' },
    { label: 'sms', value: 'sms' },
    { label: 'email', value: 'mailto' },
  ];

  const storageTypeOptions = [
    {
      label: '소식',
      value: 'rarenote://ReactionStorage?contentsType=treatmentNews',
    },
    {
      label: '임상',
      value: 'rarenote://ReactionStorage?contentsType=clinicalTrial',
    },
  ];

  const pageOptions = {
    basicType: [
      { label: '홈 탭', value: 'rarenote://Home', type: 'basic' },
      { label: '알림', value: 'rarenote://Notification', type: 'basic' },
      { label: '공지', value: 'rarenote://Notice', type: 'basic' },
      { label: '치료탭', value: 'rarenote://치료', type: 'basic' },
      {
        label: '임상 상담 인트로',
        value: 'rarenote://ClinicalTrialsConsultation',
        type: 'basic',
      },
      {
        label: '치료 콘텐츠 인트로',
        value: 'rarenote://ClinicalTrialsContentsIntro',
        type: 'basic',
      },
      {
        label: '병원 검사 데이터 등록 인트로',
        value: 'rarenote://UploadModal?screen=DataUploadIntro&type=he',
        type: 'basic',
      },
      {
        label: '질환 인증 데이터 등록 인트로',
        value: 'rarenote://UploadModal?screen=DataUploadIntro&type=dtc',
        type: 'basic',
      },
      {
        label: '유전자 검사 등록 인트로',
        value: 'rarenote://UploadModal?screen=DnaTestReportIntro',
        type: 'basic',
      },
      {
        label: '유전자 검사 등록',
        value:
          'rarenote://UploadModal?screen=DnaTestReportBeforeUpload&needsCheckInfo=true',
        type: 'basic',
      },
      {
        label: '나의 노트 탭 - 건강 기록 탭',
        value: 'rarenote://나의 노트?screen=MyNote&tab=health',
        type: 'basic',
      },
      {
        label: '나의 노트 탭 - 게시글 탭',
        value: 'rarenote://나의 노트?screen=MyNote&tab=post',
        type: 'basic',
      },
      {
        label: '나의 노트 탭 - 데이터 탭',
        value: 'rarenote://나의 노트?screen=MyNote&tab=data',
        type: 'basic',
      },
      { label: '설정', value: 'rarenote://Setting', type: 'basic' },
      {
        label: '내 정보',
        value: 'rarenote://MyProfile?needsAuth=true',
        type: 'basic',
      },
      {
        label: '내 포인트',
        value: 'rarenote://MyPoint?needsAuth=true',
        type: 'basic',
      },
      {
        label: '데이터 내역',
        value: 'rarenote://BlockchainDataRecord?needsAuth=true',
        type: 'basic',
      },
      {
        label: '등록 현황',
        value: 'rarenote://RegistrationList?needsAuth=true',
        type: 'basic',
      },
      {
        label: '카이안과 유전체 연구 인트로',
        value: 'rarenote://GeneResearchIntro?needsAuth=true',
        type: 'basic',
      },
      {
        label: '가부키 증후군 유전연구',
        value: 'rarenote://KabukiDetail?needsAuth=true',
        type: 'basic',
      },
      {
        label: '문의 및 의견',
        value: 'rarenote://MailTo',
        type: 'basic',
      },
      {
        label: '최신뉴스(의학소식) 피드',
        value: 'rarenote://NewsFeedMain?needsAuth=true',
        type: 'basic',
      },
      {
        label: '로그인 인트로',
        value: 'rarenote://LoginIntro',
        type: 'basic',
      },
      {
        label: '건강 설문 리스트',
        value: 'rarenote://HealthProfileList?needsAuth=true',
        type: 'basic',
      },
      {
        label: '추천 게시글',
        value: 'rarenote://RecommendedPostList',
        type: 'basic',
      },
      {
        label: '연구 전체 보기',
        value: 'rarenote://ResearchList',
        type: 'basic',
      },
      {
        label: '연구 참여 병원 리스트',
        value: 'rarenote://CollaborationListDetail?type=hospital',
        type: 'basic',
      },
      {
        label: '함께하는 환우회 리스트',
        value: 'rarenote://CollaborationListDetail?type=patientGroup',
        type: 'basic',
      },
      {
        label: '질환 선택',
        value:
          'rarenote://LoginIntro?screen=DiseaseSearch&from=webviewContents',
        type: 'basic',
      },
      {
        label: '병원 방문 기록 입력 페이지',
        value:
          'rarenote://RecordStack?screen=HospitalVisitInput&needsAuth=true',
        type: 'basic',
      },
      {
        label: '복약 기록 입력 페이지',
        value: 'rarenote://RecordStack?screen=MedicineInput&needsAuth=true',
        type: 'basic',
      },
      {
        label: '증상 기록 입력 페이지',
        value: 'rarenote://RecordStack?screen=SymptomInput&needsAuth=true',
        type: 'basic',
      },
      {
        label: '게시글 입력 페이지',
        value: 'rarenote://PostInput?needsAuth=true',
        type: 'basic',
      },
      {
        label: '게시글 전체보기 페이지',
        value: 'rarenote://소통?screen=Communication',
        type: 'basic',
      },
    ],
    detailType: [
      {
        label: '공지/소식 상세',
        value: 'rarenote://NoticeDetail?id=',
        type: 'detail',
      },
      {
        label: '콘텐츠/질환정보 상세',
        value: 'rarenote://MedicalNewsDetail?id=',
        type: 'detail',
      },
      {
        label: '유전자 상세',
        value: 'rarenote://GeneInformationDetail?id=',
        type: 'detail',
      },
      {
        label: '임상 상세',
        value: 'rarenote://ClinicalTrialDetail?needsAuth=true&id=',
        type: 'detail',
      },
      {
        label: '프로젝트 상세',
        value: 'rarenote://EventModal?screen=EventDetail&id=',
        type: 'detail',
      },
      {
        label: '게시글 상세',
        value: 'rarenote://Home?screen=PostDetail&needsAuth=true&id=',
        type: 'detail',
      },
      {
        label: '글로벌 뉴스 상세',
        value: 'rarenote://GlobalArticleDetail?id=',
        type: 'detail',
      },
    ],
    selectType: [
      {
        label: '보관함',
        value: 'rarenote://ReactionStorage?contentsType=',
        type: 'select',
      },
    ],
  };

  const treatmentNewsTitleTypeOptions = [
    { label: '최상단', value: 'top' },
    { label: '제목1', value: 'v3-editor-heading-1' },
    { label: '제목2', value: 'v3-editor-heading-2' },
    { label: '제목3', value: 'v3-editor-heading-3' },
    { label: '제목4', value: 'v3-editor-heading-4' },
    { label: '제목5', value: 'v3-editor-heading-5' },
  ];

  const onSingleChange = (value, e, type) => {
    setState({
      [type]: e,
    });
  };

  const changeState = useCallback((e) => {
    setState({ [e.target.name]: e.target.value });

    if (e.target.name === 'appTypeValue') {
      setState({
        resultUrl:
          e.target.value === 'share' ? 'https://rarenote.onelink.me/Mr2u/' : '',
      });
    }
  }, []);

  const onMakeUrl = (e) => {
    if (data?.hasMakeUrl) data?.hasMakeUrl(e.target.checked);
    setState({
      hasMakeUrl: e.target.checked,
    });
  };

  const textCopy = () => {
    navigator.clipboard.writeText(state?.resultUrl);
    notification.open({
      message: '복사에 성공하였습니다.',
      description:
        '링크 걸고자 하는 텍스트, 이미지 등을 선택한 후 에디터 메뉴바의 insert/edit link 버튼을 클릭하여 URL 입력칸에 입력해 주세요',
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    });
  };

  const notificationAlert = (type) => {
    if (type === 'success') {
      notification[type]({
        message: 'url을 생성하였습니다',
        description: '복사하여 사용해주세요.',
      });
    }
    if (type === 'error') {
      notification[type]({
        message: '생성할 수 없습니다.',
        description: '입력값을 확인해주세요.',
      });
    }
  };
  const isTitleTypeTop = state?.treatmentNewsTitleTypeValue === 'top';
  const makeExternalUrl = () => {
    if (state?.appTypeValue === 'etc') {
      if (state?.externalUrl === '') {
        notificationAlert('error');
      } else {
        notificationAlert('success');
        setState({
          resultUrl: state?.externalUrl,
        });
      }
    }

    if (state?.appTypeValue === 'email') {
      if (state?.emailUrl === '') {
        notificationAlert('error');
      } else {
        notificationAlert('success');
        setState({
          resultUrl: `${state?.emailTypeValue}:${state?.emailUrl}`,
        });
      }
    }

    if (state?.appTypeValue === 'rarenote') {
      if (state?.rarenoteValue?.id?.includes('basic')) {
        notificationAlert('success');
        setState({
          resultUrl: state?.rarenoteValue?.value,
        });
      }

      if (state?.rarenoteValue?.children === '공지/소식 상세') {
        if (Object.keys(state?.noticeValue).length === 0) {
          notificationAlert('error');
        } else {
          notificationAlert('success');
          setState({
            resultUrl: `${
              state?.rarenoteValue?.value + state?.noticeValue?.key
            }`,
          });
        }
      }

      if (state?.rarenoteValue?.children === '콘텐츠/질환정보 상세') {
        if (
          Object.keys(state?.treatmentNewsValue).length === 0 ||
          (!isTitleTypeTop && state?.treatmentNewsTitleValue === '')
        ) {
          notificationAlert('error');
        } else {
          notificationAlert('success');
          setState({
            resultUrl: `${
              state?.rarenoteValue?.value + state?.treatmentNewsValue?.key
            }${
              isTitleTypeTop
                ? ''
                : `&scrollToClass=${state?.treatmentNewsTitleTypeValue}&scrollTo=${state?.treatmentNewsTitleValue}`
            }`,
          });
        }
      }
      if (state?.rarenoteValue?.children === '유전자 상세') {
        if (Object.keys(state?.dnaValue).length === 0) {
          notificationAlert('error');
        } else {
          notificationAlert('success');
          setState({
            resultUrl: `${
              state?.rarenoteValue?.value + state?.dnaValue?.key
            }&title=${state?.dnaValue?.value}`,
          });
        }
      }
      if (state?.rarenoteValue?.children === '임상 상세') {
        if (state?.clinicalTrialTitleValue === '') {
          notificationAlert('error');
        } else {
          notificationAlert('success');
          setState({
            resultUrl: `${
              state?.rarenoteValue?.value + state?.clinicalTrialTitleValue
            }&needsAuth=true`,
          });
        }
      }
      if (state?.rarenoteValue?.children === '프로젝트 상세') {
        if (Object.keys(state?.projectValue).length === 0) {
          notificationAlert('error');
        } else {
          notificationAlert('success');
          setState({
            resultUrl: `${
              state?.rarenoteValue?.value + state?.projectValue?.key
            }`,
          });
        }
      }
      if (state?.rarenoteValue?.children === '게시글 상세') {
        if (state?.togetherTitleValue === '') {
          notificationAlert('error');
        } else {
          notificationAlert('success');
          setState({
            resultUrl: `${
              state?.rarenoteValue?.value + state?.togetherTitleValue
            }`,
          });
        }
      }
      if (state?.rarenoteValue?.children === '글로벌 뉴스 상세') {
        if (Object.keys(state?.globalNewsValue).length === 0) {
          notificationAlert('error');
        } else {
          notificationAlert('success');
          setState({
            resultUrl: `${
              state?.rarenoteValue?.value + state?.globalNewsValue?.key
            }`,
          });
        }
      }
      if (state?.rarenoteValue?.children === '보관함') {
        notificationAlert('success');
        setState({
          resultUrl: state?.reactionStorage,
        });
      }
    }
  };

  return (
    <>
      <Form.Item label={data?.type === 'contents' ? 'URL생성' : '링크 선택'}>
        <div style={{ padding: 16, backgroundColor: '#EDF0F2' }}>
          <Checkbox onChange={onMakeUrl} checked={state?.hasMakeUrl}>
            {data?.type === 'contents'
              ? '어드민 에디터 본문에 적용할 URL생성'
              : '배너 클릭 시 이동할 앱 내 페이지 선택하기'}
          </Checkbox>

          {state?.hasMakeUrl && (
            <>
              {data?.type === 'contents' && (
                <Row style={{ marginTop: 24 }}>
                  <Col span={24}>
                    <Radio.Group
                      name="appTypeValue"
                      onChange={changeState}
                      value={state.appTypeValue}
                      optionType="button"
                      buttonStyle="solid"
                      options={typeOptions}
                    />
                    <span style={{ paddingLeft: 8 }}>
                      이동 하고자 하는 url 타입을 골라주세요
                    </span>
                  </Col>
                </Row>
              )}
              {/* 외부 url 연결 */}
              {state?.appTypeValue === 'etc' && (
                <Row style={{ marginTop: 24 }}>
                  <Col span={24}>
                    <Form.Item label="외부 url">
                      <Input
                        name="externalUrl"
                        placeholder="외부 url을 입력해주세요.(ex: https://www.naver.com/)"
                        onBlur={changeState}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {/* tel, sms, email */}
              {state?.appTypeValue === 'email' && (
                <Row style={{ marginTop: 24 }}>
                  <Col span={24}>
                    <Radio.Group
                      name="emailTypeValue"
                      onChange={changeState}
                      value={state.emailTypeValue}
                      options={emailTypeOptions}
                    />
                    <Form.Item label="주소 입력" style={{ marginTop: 24 }}>
                      <Input
                        name="emailUrl"
                        placeholder="tel, sms, 이메일을 입력해주세요.(ex: abc@humanscape.io)"
                        onBlur={changeState}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {/* 앱 내 이동 */}
              {state?.appTypeValue === 'rarenote' && (
                <Row style={{ marginTop: 24 }}>
                  <Col span={24}>
                    <Form.Item label="앱 내 페이지">
                      <Select
                        style={{ width: '100%' }}
                        placeholder="이동할 앱 내 페이지를 선택해주세요"
                        onChange={(value, e) =>
                          onSingleChange(value, e, 'rarenoteValue')
                        }
                      >
                        <OptGroup label="기본페이지">
                          {pageOptions?.basicType?.map(
                            (page) =>
                              page?.type === 'basic' && (
                                <Option
                                  key={page?.value}
                                  id={Object.values(page)?.toString()}
                                  value={page?.value}
                                >
                                  {page.label}
                                </Option>
                              ),
                          )}
                        </OptGroup>
                        <OptGroup label="디테일 페이지">
                          {pageOptions?.detailType?.map((page) =>
                            page?.type === 'detail' ? (
                              <Option
                                key={page?.value}
                                id={page?.value}
                                value={page?.value}
                              >
                                {page.label}
                              </Option>
                            ) : (
                              <> </>
                            ),
                          )}
                        </OptGroup>
                        <OptGroup label="선택 페이지">
                          {pageOptions?.selectType?.map((page) =>
                            page?.type === 'select' ? (
                              <Option
                                key={page?.value}
                                id={page?.value}
                                value={page?.value}
                              >
                                {page.label}
                              </Option>
                            ) : (
                              <> </>
                            ),
                          )}
                        </OptGroup>
                      </Select>
                    </Form.Item>
                  </Col>
                  {state?.rarenoteValue?.children === '공지/소식 상세' && (
                    <Col span={24} style={{ marginBottom: 24 }}>
                      <Form.Item label="공지/소식">
                        <Select
                          style={{ width: '100%' }}
                          placeholder="공지/소식을 선택해주세요"
                          showSearch
                          onChange={(value, e) =>
                            onSingleChange(value, e, 'noticeValue')
                          }
                        >
                          {data?.notices?.map((notice) => (
                            <Option
                              key={notice?.id}
                              id={Object?.values(notice).toString()}
                              value={notice?.title}
                            >
                              {notice.title}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  {state?.rarenoteValue?.children ===
                    '콘텐츠/질환정보 상세' && (
                    <Col span={24} style={{ marginBottom: 24 }}>
                      <Form.Item label="콘텐츠/질환정보">
                        <Select
                          style={{ width: '100%' }}
                          placeholder="콘텐츠를 선택해주세요"
                          showSearch
                          onChange={(value, e) =>
                            onSingleChange(value, e, 'treatmentNewsValue')
                          }
                        >
                          {data?.treatmentNews?.map((treatment) => (
                            <Option
                              key={treatment?.id}
                              id={Object?.values(treatment).toString()}
                              value={treatment?.title}
                            >
                              {treatment.title}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item label="제목타입">
                        <Radio.Group
                          name="treatmentNewsTitleTypeValue"
                          onChange={changeState}
                          value={state.treatmentNewsTitleTypeValue}
                          options={treatmentNewsTitleTypeOptions}
                        />
                      </Form.Item>
                      <Form.Item label="제목">
                        <Input
                          name="treatmentNewsTitleValue"
                          placeholder="콘텐츠 제목을 입력해주세요.(ex: 신약 발견 단계)"
                          onBlur={changeState}
                          disabled={isTitleTypeTop}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {state?.rarenoteValue?.children === '유전자 상세' && (
                    <Col span={24} style={{ marginBottom: 24 }}>
                      <Form.Item label="유전자">
                        <Select
                          style={{ width: '100%' }}
                          placeholder="유전자를 선택해주세요"
                          showSearch
                          onChange={(value, e) =>
                            onSingleChange(value, e, 'dnaValue')
                          }
                        >
                          {data?.dnas?.map((dna) => (
                            <Option
                              key={dna?.id}
                              id={Object?.values(dna).toString()}
                              value={dna?.name}
                            >
                              {dna?.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  {state?.rarenoteValue?.children === '임상 상세' && (
                    <Col span={24} style={{ marginBottom: 24 }}>
                      <Form.Item label="임상 ctkId">
                        <Input
                          name="clinicalTrialTitleValue"
                          placeholder="임상 ctkId를 입력해주세요.(ex: 57537)"
                          onBlur={changeState}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {state?.rarenoteValue?.children === '프로젝트 상세' && (
                    <Col span={24} style={{ marginBottom: 24 }}>
                      <Form.Item label="프로젝트">
                        <Select
                          style={{ width: '100%' }}
                          placeholder="프로젝트를 선택해주세요"
                          showSearch
                          onChange={(value, e) =>
                            onSingleChange(value, e, 'projectValue')
                          }
                        >
                          {data?.projects?.map((project) => (
                            <Option
                              key={project?.id}
                              id={Object?.values(project).toString()}
                              value={project?.projectTitle}
                            >
                              {project?.projectTitle}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  {state?.rarenoteValue?.children === '게시글 상세' && (
                    <Col span={24} style={{ marginBottom: 24 }}>
                      <Form.Item label="게시글 ID">
                        <Input
                          name="togetherTitleValue"
                          placeholder="게시글 ID를 입력해주세요.(ex: 473)"
                          onBlur={changeState}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {state?.rarenoteValue?.children === '글로벌 뉴스 상세' && (
                    <Col span={24} style={{ marginBottom: 24 }}>
                      <Form.Item label="글로벌 뉴스">
                        <Select
                          style={{ width: '100%' }}
                          placeholder="글로벌 뉴스를 선택해주세요"
                          showSearch
                          onChange={(value, e) =>
                            onSingleChange(value, e, 'globalNewsValue')
                          }
                        >
                          {data?.rssFeed?.map((p) => (
                            <Option
                              key={p?.id}
                              id={Object?.values(p).toString()}
                              value={p?.title}
                            >
                              {p.title}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  {state?.rarenoteValue?.children === '보관함' && (
                    <Col span={24} style={{ marginBottom: 24 }}>
                      <Radio.Group
                        name="reactionStorage"
                        onChange={changeState}
                        value={state.reactionStorage}
                        options={storageTypeOptions}
                      />
                    </Col>
                  )}
                </Row>
              )}
              {state?.appTypeValue !== 'share' && (
                <Row>
                  <Col span={24}>
                    <Button
                      type="primary"
                      onClick={makeExternalUrl}
                      style={{ width: 100 }}
                    >
                      생성하기
                    </Button>
                  </Col>
                </Row>
              )}
              {state?.resultUrl !== '' && (
                <>
                  <hr />
                  <Row style={{ marginTop: 24 }}>
                    <Col span={20}>
                      <Form.Item label="결과 URL">
                        <Input
                          placeholder="결과 URL"
                          value={state?.resultUrl}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      {data?.type === 'contents' && (
                        <Button
                          type="primary"
                          onClick={textCopy}
                          style={{ width: '100%' }}
                        >
                          복사하기
                        </Button>
                      )}
                      {data?.type === 'entryModal' && (
                        <Button
                          type="primary"
                          onClick={() => data?.textCopy(state?.resultUrl)}
                          style={{ width: '100%' }}
                        >
                          링크 확정
                        </Button>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </div>
      </Form.Item>
    </>
  );
};

export default MoveAppUrl;
