export const agreeMap = { true: 'O', false: 'X' };

export const genderMap = { male: '남', female: '여', [null]: '' };

export const doctorProfileTypeMap = {
  doctor: '의료진',
  group: '단체',
  [null]: '',
};

export const statusMap = {
  IN_PROGRESS: '진행중',
  SUBMITTED: '신청완료',
};

export const cardTypeMap = {
  card: '카드',
  research: '연구 참여',
  consulting: '1:1 상담',
};

export const releaseTargetMap = {
  all: '유저 전체',
  login: '로그인 유저',
  nonLogin: '비로그인 유저',
  isAnyNotiOff: '앱푸시 설정 안함',
};

export const userDataProcessStatusMap = {
  diseaseTypeCertification: '질환 인증 등록 안함',
  hospitalExaminationRecord: '병원 검사 등록 안함',
  dnaTestReport: '유전자검사 등록 안함',
};

export const counselingStatusOptions = [
  { key: '신청', text: '신청', value: 'apply' },
  { key: '재신청', text: '재신청', value: 'reapply' },
  { key: '상담중', text: '상담중', value: 'processing' },
  { key: '상담완료', text: '상담완료', value: 'complete' },
  { key: '상담불가', text: '상담불가', value: 'wrong' },
  { key: '상담취소', text: '상담취소', value: 'cancel' },
];

export const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 16 },
  },
};

export const largeLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 20 },
  },
};

export const relationshipOptions = [
  { key: '환자', text: '환자', value: '환자' },
  { key: '보호자', text: '보호자', value: '보호자' },
  { key: '의료진', text: '의료진', value: '의료진' },
  { key: '연구원', text: '연구원', value: '연구원' },
  { key: '제약회사 관계자', text: '제약회사 관계자', value: '제약회사 관계자' },
  { key: '기타', text: '기타', value: '기타' },
];

export const relationshipFilter = [
  { text: '환자', value: '환자' },
  { text: '보호자', value: '보호자' },
  { text: '의료진', value: '의료진' },
  { text: '연구원', value: '연구원' },
  { text: '제약회사 관계자', value: '제약회사 관계자' },
  { text: '기타', value: '기타' },
];

export const diagnosisOptions = [
  { label: '일반 진단서', value: '일반 진단서' },
  { label: '영문 진단서', value: '영문 진단서' },
  { label: '건강 진단서', value: '건강 진단서' },
  { label: '병무용 진단서', value: '병무용 진단서' },
  { label: '상해 진단서', value: '상해 진단서' },
  { label: '사망 진단서', value: '사망 진단서' },
  { label: '후유 장해 진단서', value: '후유 장해 진단서' },
  { label: '장애 정도 심사용 진단서', value: '장애 정도 심사용 진단서' },
  { label: '근로 능력 평가용 진단서', value: '근로 능력 평가용 진단서' },
];

export const opinionOptions = [
  { label: '일반 소견서', value: '일반 소견서' },
  { label: '영문 소견서', value: '영문 소견서' },
  {
    label: '노인장기요양보험 의사 소견서',
    value: '노인장기요양보험 의사 소견서',
  },
];

export const hospitalizationOptions = [
  { label: '입원사실 증명서', value: '입원사실 증명서' },
  { label: '입퇴원 확인서', value: '입퇴원 확인서' },
];

export const treatmentOptions = [
  { label: '진료 의뢰서', value: '진료 의뢰서' },
  { label: '진료 사실 확인서', value: '진료 사실 확인서' },
  { label: '외래 진료 확인서', value: '외래 진료 확인서' },
  { label: '통원 사실 증명서', value: '통원 사실 증명서' },
  { label: '통원 진료 확인서', value: '통원 진료 확인서' },
  { label: '초진 기록지', value: '초진 기록지' },
  { label: '진료 기록 사본', value: '진료 기록 사본' },
  { label: '방사선 치료 확인서', value: '방사선 치료 확인서' },
  { label: '수술 기록지', value: '수술 기록지' },
  { label: '수술 확인서', value: '수술 확인서' },
  { label: '영상 검사', value: '영상 검사' },
  { label: '진단 검사', value: '진단 검사' },
  { label: '유전학 검사', value: '유전학 검사' },
  { label: '조직학 검사', value: '조직학 검사' },
  { label: '그 외 검사', value: '그 외 검사' },
];

export const prescriptionOptions = [
  { label: '원내 처방전', value: '원내 처방전' },
  { label: '원외 처방전', value: '원외 처방전' },
  { label: '영문 처방전', value: '영문 처방전' },
  { label: '보장구 처방전', value: '보장구 처방전' },
];

export const etcOptions = [
  { label: '산정 특례', value: '산정 특례' },
  { label: '장애인 증명서', value: '장애인 증명서' },
  { label: '기타', value: '기타' },
  { label: '없음', value: '' },
];

export const cardTypeOptions = [
  { label: '카드', value: 'card' },
  { label: '연구 참여', value: 'research' },
  { label: '1:1 상담', value: 'consulting' },
];

export const releaseTargetOptions = [
  { label: '유저 전체 (로그인+비로그인)', value: 'all' },
  { label: '로그인 유저', value: 'login' },
  { label: '비로그인 유저', value: 'nonLogin' },
  { label: '앱푸시 설정 안함', value: 'isAnyNotiOff' },
];

export const userDataProcessStatusOption = [
  { label: '질환 인증 등록 안함', value: 'diseaseTypeCertification' },
  { label: '병원 검사 등록 안함', value: 'hospitalExaminationRecord' },
  { label: '유전자검사 등록 안함', value: 'dnaTestReport' },
];

export const healthProfileTypeOption = [
  { label: '단일 선택 (radio)', value: 'radio' },
  { label: '연/월/일 선택 (date)', value: 'date' },
  { label: '연/월/일 필수 입력', value: 'yearMonthDayText' },
  { label: '연/월 필수 입력', value: 'yearMonthText' },
  { label: '텍스트 입력 (text)', value: 'text' },
  { label: '복수 선택, 텍스트 입력 (checkbox)', value: 'checkbox' },
  { label: '목록 선택 (dropdown)', value: 'dropdown' },
  { label: '년 선택 (yearDropdown)', value: 'yearDropdown' },
  { label: '단일 선택, 텍스트 입력 (radioText)', value: 'radioText' },
  { label: '단계 (progress)', value: 'progress' },
  { label: '단위 입력 (number)', value: 'number' },
  { label: '[기능 미개발] multiText', value: 'multiText', disabled: true },
  { label: '[기능 미개발] select', value: 'select', disabled: true },
];

export const patientGroupLevelMap = {
  beforeRegistration: '단체 등록 전',
  NPO: '임의 단체',
  NPC: '사단법인',
};

export const patientGroupHealthProfileStatusMap = {
  recruiting: '모집 중',
  completed: '완료',
  None: '안함',
};

export const levelOptions = [
  { label: '단체 등록 전', value: 'beforeRegistration' },
  { label: '임의 단체', value: 'NPO' },
  { label: '사단법인', value: 'NPC' },
];

export const healthProfileStatusOptions = [
  { label: '모집 중', value: 'recruiting' },
  { label: '결과 발행 (종료)', value: 'completed' },
];

export const donationTypeOptions = [
  { label: '안함', value: false },
  { label: '연결함', value: true },
];

export const healthProfileStatusFilter = [
  { text: '모집 중', value: 'recruiting' },
  { text: '완료', value: 'completed' },
  { text: '안함', value: 'None' },
];

export const levelFilter = [
  { text: '단체 등록 전', value: 'beforeRegistration' },
  { text: '임의 단체', value: 'NPO' },
  { text: '사단법인', value: 'NPC' },
];

export const researchStatusOptions = [
  { label: '연구 준비 중', value: 'ready' },
  { label: '연구 모집 중', value: 'recruiting' },
  { label: '연구 완료', value: 'completed' },
];

export const researchStatusMap = {
  ready: '연구 준비 중',
  recruiting: '연구 모집 중',
  completed: '연구 완료',
};

export const researchTypeOptions = [
  { label: '홍보 및 건강 설문', value: 'promotion_and_healthprofile' },
  { label: '건강 설문', value: 'healthprofile' },
  { label: '기존 연구 상세 페이지 있음', value: 'custom_detail_page' },
];

export const researchSharedOptions = [
  { label: '공유 함', value: true },
  { label: '공유 안함', value: false },
];

export const isRequestSurveyOptions = [
  { label: '설정 안함', value: false },
  {
    label:
      '설정 함 (설문 완료가 신청 완료 페이지로 변경되고, 공유 모달이 뜹니다.)',
    value: true,
  },
];

export const entryModalTargetOptions = [
  { label: '질환 전체', value: 'all' },
  { label: '특정 질환', value: 'select' },
];
