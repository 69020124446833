import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { antTinymceInit } from '../../util/antTinymceInit';
import { antTinymceStyleInit } from '../../util/antTinymceStyleInit';
import {
  getSignedUrl,
  changeAccessRangeToPublic,
} from '../../services/fileUploadService';

const tinymceApiKey = process.env.REACT_APP_TINYMCE_API_KEY || '';
const AntTinymceInput = ({
  content,
  setContent,
  setUploadUrl,
  onBlur,
  setImageLoading,
  contentLinkData,
  isAntTinymceInit = true,
}) => {
  const images_upload_handler = async (blobInfo, success, failure) => {
    setImageLoading(true);
    const newSignedUrlUploadData = await getSignedUrl({
      params: {
        functionType: 'PUT',
        fileName: blobInfo ? blobInfo.filename() : null,
        contentType: blobInfo ? blobInfo.blob().type : null,
      },
    });
    const data = newSignedUrlUploadData;
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', data.signedUrl);
    xhr.onload = async () => {
      await changeAccessRangeToPublic({
        filePath: newSignedUrlUploadData?.filePath,
      });
      success(newSignedUrlUploadData.publicUrl);
      if (setUploadUrl) setUploadUrl(newSignedUrlUploadData.publicUrl);
      setImageLoading(false);
    };
    xhr.onerror = () => {
      alert('이미지 업로드에 실패했습니다!');
      failure(newSignedUrlUploadData.publicUrl);
      setImageLoading(false);
    };
    xhr.setRequestHeader('Content-Type', blobInfo.blob().type);
    xhr.send(blobInfo.blob());
  };

  const applyDnaLink = (contents) => {
    // Safari does not support lookbehind: '<u>text</u>' -> non '>' characters between <u> and </u>
    const pattern = /[^>]+(?=<\/u>)/g;
    contentLinkData.setState({
      selectedDictionaries: contents.match(pattern) ?? [],
    });
  };

  return (
    <Editor
      value={content}
      apiKey={tinymceApiKey}
      init={{
        ...(isAntTinymceInit ? antTinymceInit : antTinymceStyleInit),
        images_upload_handler,
        setup(editor) {
          editor.ui.registry.addMenuButton('mybutton', {
            text: '레어노트 스타일',
            fetch: (callback) => {
              const items = [
                {
                  type: 'nestedmenuitem',
                  text: '제목',
                  icon: 'permanent-pen',
                  getSubmenuItems: () => [
                    {
                      type: 'menuitem',
                      text: '제목2',
                      icon: 'permanent-pen',
                      onAction: () =>
                        editor.insertContent(
                          '<h2 class="v3-editor-heading-2">제목2</h2>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '제목3',
                      icon: 'permanent-pen',
                      onAction: () =>
                        editor.insertContent(
                          '<h3 class="v3-editor-heading-3">제목3</h3>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '제목4',
                      icon: 'permanent-pen',
                      onAction: () =>
                        editor.insertContent(
                          '<h4 class="v3-editor-heading-4">제목4</h4>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '제목5',
                      icon: 'permanent-pen',
                      onAction: () =>
                        editor.insertContent(
                          '<h5 class="v3-editor-heading-5">제목5</h5>',
                        ),
                    },
                  ],
                },
                {
                  type: 'nestedmenuitem',
                  text: '본문',
                  icon: 'edit-block',
                  getSubmenuItems: () => [
                    {
                      type: 'menuitem',
                      text: '기본',
                      icon: 'edit-block',
                      onAction: () =>
                        editor.insertContent(
                          '<p class="v3-editor-content-paragraph">본문</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '숫자',
                      icon: 'edit-block',
                      onAction: () =>
                        editor.insertContent(
                          '<ol class="v3-editor-content-list"><li>text</li><li>text</li><li>text</li><li>text</li></ol>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '도트',
                      icon: 'edit-block',
                      onAction: () =>
                        editor.insertContent(
                          '<ul class="v3-editor-content-list"><li>text</li><li>text</li><li>text</li><li>text</li></ul>',
                        ),
                    },
                  ],
                },
                // 작은 텍스트
                {
                  type: 'nestedmenuitem',
                  text: '강조',
                  icon: 'table-left-header',
                  getSubmenuItems: () => [
                    {
                      type: 'menuitem',
                      text: '기본',
                      onAction: () =>
                        editor.insertContent(
                          '<dl class="v3-editor-content-point"><dt>제목</dt><dd>내용</dd></dl>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '책',
                      onAction: () =>
                        editor.insertContent(
                          '<dl class="v3-editor-content-point-book"><dt>제목</dt><dd>내용</dd></dl>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '돋보기',
                      onAction: () =>
                        editor.insertContent(
                          '<dl class="v3-editor-content-point-search"><dt>제목</dt><dd>내용</dd></dl>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '전구',
                      onAction: () =>
                        editor.insertContent(
                          '<dl class="v3-editor-content-point-light-bulb"><dt>제목</dt><dd>내용</dd></dl>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '의료진 협업',
                      onAction: () =>
                        editor.insertContent(
                          '<dl class="v3-editor-content-point" data-point-type="POLYGONCHECK"><dt>제목</dt><dd>내용</dd></dl>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '체크_블랙',
                      onAction: () =>
                        editor.insertContent(
                          '<dl class="v3-editor-content-point-check-black"><dt>제목</dt><dd>내용</dd></dl>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '체크_블루',
                      onAction: () =>
                        editor.insertContent(
                          '<dl class="v3-editor-content-point-check-blue"><dt>제목</dt><dd>내용</dd></dl>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '체크_레드',
                      onAction: () =>
                        editor.insertContent(
                          '<dl class="v3-editor-content-point-check-red"><dt>제목</dt><dd>내용</dd></dl>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '체크_그린',
                      onAction: () =>
                        editor.insertContent(
                          '<dl class="v3-editor-content-point-check-green"><dt>제목</dt><dd>내용</dd></dl>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '체크_옐로우',
                      onAction: () =>
                        editor.insertContent(
                          '<dl class="v3-editor-content-point-check-yellow"><dt>제목</dt><dd>내용</dd></dl>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '로고_서울대학교',
                      onAction: () =>
                        editor.insertContent(
                          '<dl class="v3-editor-content-point" data-point-type="SNU"><dt>제목</dt><dd>내용</dd></dl>',
                        ),
                    },
                  ],
                },
                {
                  type: 'menuitem',
                  text: '인용구',
                  icon: 'quote',
                  onAction: () =>
                    editor.insertContent(
                      '<blockquote class="v3-editor-content-blockquote">말풍선</blockquote>',
                    ),
                },
                {
                  type: 'menuitem',
                  text: '용어 설명',
                  icon: 'translate',
                  onAction: () =>
                    editor.insertContent(
                      '<dl class="v3-editor-content-dictionary"><dt>제목</dt><dd>내용</dd></dl>',
                    ),
                },
                {
                  type: 'nestedmenuitem',
                  text: '간격',
                  icon: 'duplicate-row',
                  getSubmenuItems: () => [
                    {
                      type: 'menuitem',
                      text: '여백',
                      icon: 'duplicate-row',
                      onAction: () =>
                        editor.insertContent(
                          '<hr class="v3-editor-content-blank-space" />',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '선',
                      icon: 'duplicate-row',
                      onAction: () =>
                        editor.insertContent(
                          '<hr class="v3-editor-content-line" />',
                        ),
                    },
                  ],
                },
                {
                  type: 'nestedmenuitem',
                  text: '링크 버튼',
                  icon: 'list-bull-circle',
                  getSubmenuItems: () => [
                    {
                      type: 'menuitem',
                      text: '기본',
                      onAction: () =>
                        editor.insertContent(
                          '<ul class="v3-editor-content-link"><li><a href="">출처1</a></li><li><a href="">출처2</a></li><li><a href="">출처3</a></li><li><a href="">출처4</a></li></ul>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '숫자',
                      onAction: () =>
                        editor.insertContent(
                          '<ol class="v3-editor-content-link"><li><a href="">출처1</a></li><li><a href="">출처2</a></li><li><a href="">출처3</a></li><li><a href="">출처4</a></li></ol>',
                        ),
                    },
                  ],
                },
                {
                  type: 'menuitem',
                  text: '참고 문헌',
                  icon: 'edit-block',
                  onAction: () =>
                    editor.insertContent(
                      '<ol class="v3-editor-content-reference"><li>참고 문헌1</li><li>참고 문헌2</li><li>참고 문헌3</li><li>참고 문헌4</li></ol>',
                    ),
                },
                {
                  type: 'nestedmenuitem',
                  text: 'CTA 버튼',
                  icon: 'browse',
                  getSubmenuItems: () => [
                    {
                      type: 'menuitem',
                      text: '병원 인증',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-he"><div><h4>title</h4><p>text</p><a href="rarenote://UploadModal?screen=DataUploadIntro&type=he">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '질환 인증',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-dc"><div><h4>title</h4><p>text</p><a href="rarenote://UploadModal?screen=DataUploadIntro&type=dtc">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '유전자 검사',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-dtr"><div><h4>title</h4><p>text</p><a href="rarenote://UploadModal?screen=DnaTestReportIntro">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '치료',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-ctd"><div><h4>title</h4><p>text</p><a href="rarenote://치료">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '콘텐츠(링크입력)',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-mnd"><div><h4>title</h4><p>text</p><a href="">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '공지(링크입력)',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-notice"><div><h4>title</h4><p>text</p><a href="">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '문의사항',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-mail"><div><h4>title</h4><p>text</p><a href="mailto:help@humanscape.io">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '검색(링크입력)',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-search"><div><h4>title</h4><p>text</p><a href="">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '상담',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-consult"><div><h4>title</h4><p>text</p><a href="rarenote://ClinicalTrialsConsultation">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '알림',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-alarm"><div><h4>title</h4><p>text</p><a href="rarenote://Setting">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '회원가입',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-join"><div><h4>title</h4><p>text</p><a href="rarenote://LoginIntro">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '레어 아카데미',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta" data-type="academy"><div><h4>title</h4><p>text</p><a href="">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '건강 설문',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta" data-type="healthProfile"><div><h4>title</h4><p>text</p><a href="">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '병원 인증_서브x',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-he"><div><h4>title</h4><a href="rarenote://UploadModal?screen=DataUploadIntro&type=he">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '질환 인증_서브x',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-dc"><div><h4>title</h4><a href="rarenote://UploadModal?screen=DataUploadIntro&type=dtc">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '유전자 검사_서브x',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-dtr"><div><h4>title</h4><a href="rarenote://UploadModal?screen=DnaTestReportIntro">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '치료_서브x',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-ctd"><div><h4>title</h4><a href="rarenote://치료">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '콘텐츠(링크입력)_서브x',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-mnd"><div><h4>title</h4><a href="">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '공지(링크입력)_서브x',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-notice"><div><h4>title</h4><a href="">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '문의사항_서브x',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-mail"><div><h4>title</h4><a href="mailto:help@humanscape.io">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '검색(링크입력)_서브x',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-search"><div><h4>title</h4><a href="">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '상담_서브x',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-consult"><div><h4>title</h4><a href="rarenote://ClinicalTrialsConsultation">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '알림_서브x',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-alarm"><div><h4>title</h4><a href="rarenote://Setting">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '회원가입_서브x',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta-join"><div><h4>title</h4><a href="rarenote://LoginIntro">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '레어 아카데미_서브x',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta" data-type="academy"><div><h4>title</h4><a href="">label</a></div></div><p>p태그</p>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '건강 설문_서브x',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-cta" data-type="healthProfile"><div><h4>title</h4><a href="">label</a></div></div><p>p태그</p>',
                        ),
                    },
                  ],
                },
                {
                  type: 'nestedmenuitem',
                  text: '헬프라인',
                  icon: 'document-properties',
                  getSubmenuItems: () => [
                    {
                      type: 'menuitem',
                      text: '헬프라인 본문',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-paragraph-helpline">본문</div>',
                        ),
                    },
                    {
                      type: 'menuitem',
                      text: '헬프라인 출처',
                      onAction: () =>
                        editor.insertContent(
                          '<div class="v3-editor-content-reference-helpline">출처1</div>',
                        ),
                    },
                  ],
                },
                {
                  type: 'menuitem',
                  text: '로티 이미지',
                  icon: 'edit-block',
                  onAction: () =>
                    editor.insertContent(
                      '<p><lottie-player src="https://assets9.lottiefiles.com/packages/lf20_PBgNop.json" style="width: 100%; height: auto; aspect-ratio: 1;" autoplay="autoplay" loop="loop" background="transparent" speed="1"></lottie-player>&nbsp;</p>',
                    ),
                },
                {
                  type: 'menuitem',
                  text: '영상 콘텐츠',
                  icon: 'embed',
                  onAction: () =>
                    editor.insertContent(
                      '<p><iframe class="v3-editor-video-container" src="https://www.youtube.com/embed/fiA43_GA9lM" frameborder="0" allowfullscreen=""></iframe></p>',
                    ),
                },
              ];
              callback(items);
            },
          });

          if (onBlur) {
            editor.on('blur', () => {
              applyDnaLink(editor.getContent());
              setContent(editor.getContent());
              onBlur(editor.getDoc());
            });
          }
          editor.on('NodeChange', () => {
            const imageList = editor.getDoc().getElementsByTagName('img');
            for (let i = 0; i < imageList.length; i += 1) {
              editor.dom.setAttribs(imageList[i], {
                width: '100%',
                height: null,
              });
            }
          });
          editor.addShortcut('meta+m', 'addShortcut subscript.', () => {
            editor.execCommand('subscript');
          });
        },
      }}
    />
  );
};
export default AntTinymceInput;
