import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  Form,
  Input,
  Radio,
  Button,
  Row,
  Col,
  notification,
  Divider,
  Skeleton,
} from 'antd';
import 'antd/dist/antd.css';
import { FileTwoTone } from '@ant-design/icons';
import {
  fetchUser as fetchUserService,
  patchTestUser as patchTestUserService,
  patchUserProfiles as patchUserProfilesService,
  fetchUnmaskedInfo as fetchUnmaskedInfoService,
} from '../../../services/userService';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import { useFetch } from '../../../hooks/useRequest';
import * as listMap from '../../../util/listMap';
import system from '../../../img/system.png';
import humanscapeIcn from '../../../img/icn/humanscape.png';
import naverIcn from '../../../img/icn/naver.png';
import googleIcn from '../../../img/icn/google.png';
import kakaoIcn from '../../../img/icn/kakao.png';
import appleIcn from '../../../img/icn/apple.png';

const UserInfo = (userId) => {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [unmaskedType, setUnmaskedType] = useState('');
  const [getMasked, setGetMasked] = useState('');
  const [userIsTest, setUserIsTest] = useState(false);
  const [userIsInfluencer, setUserIsInfluencer] = useState(false);
  const userDataFetchDone = useRef(false);
  const { done: fetchUserDone, data: userData, call: fetchUser } = useFetch(
    null,
    fetchUserService,
    userId?.userId,
  );
  const {
    done: fetchUnmaskedInfoDone,
    data: fetchUnmaskedInfoData,
    call: fetchUnmaskedInfo,
    initialize: initializeDone,
  } = useFetch(null, fetchUnmaskedInfoService, {
    userId: userId?.userId,
    type: unmaskedType,
  });

  const {
    done: patchTestUserDone,
    call: patchTestUser,
    initialize: initializePatchTestUser,
  } = useFetch(null, patchTestUserService, userId?.userId, {
    isTest: userIsTest,
  });

  const {
    done: patchUserProfilesDone,
    call: patchUserProfiles,
    initialize: initializePatchUserProfiles,
  } = useFetch(null, patchUserProfilesService, userId?.userId, {
    isInfluencer: userIsInfluencer,
  });

  useEffect(() => {
    if (!fetchUserDone && userId && !userDataFetchDone.current) {
      dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
      fetchUser();
      userDataFetchDone.current = true;
    }
  }, [fetchUser, fetchUserDone, userId]);

  const onChangeMasked = (type) => {
    setUnmaskedType(type);
    setGetMasked(true);
  };

  useEffect(() => {
    if (getMasked) {
      fetchUnmaskedInfo();
      setGetMasked(false);
    }
  }, [getMasked]);

  useEffect(() => {
    if (fetchUnmaskedInfoDone) {
      initializeDone();
      notification.success({
        message: '마스킹을 해제하였습니다.',
        description: '마스킹을 해제하였습니다.',
      });
    }
  }, [fetchUnmaskedInfoDone]);

  useEffect(() => {
    if (fetchUserDone) {
      form.setFieldsValue({
        email: userData?.email,
        name: userData?.name,
        nickname: userData?.userProfile?.nickname,
        phone: userData?.phone,
        optionalPhone: userData?.optionalPhone,
        age: `${userData?.age}세`,
        gender: listMap.genderMap[userData?.gender],
        isIdentified: listMap.agreeMap[userData?.isIdentified],
        isCertified: listMap.agreeMap[userData?.isCertified],
        agreementMarketing: listMap.agreeMap[userData?.agreementMarketing],
        clinicalTrialsNotiOn: listMap.agreeMap[userData?.clinicalTrialsNotiOn],
        isUnder14: listMap.agreeMap[userData?.isUnder14],
        funnelMainAnswer: userData?.funnelMainAnswer,
        funnelSubAnswer: userData?.funnelSubAnswer,
        diseaseType: userData?.diseaseType,
        relationship: userData?.relationship,
        isTest: userData?.isTest,
      });
      setUserIsTest(userData?.isTest);
      setUserIsInfluencer(userData?.userProfile?.isInfluencer);
    }
  }, [fetchUserDone]);

  const loading = !userData;

  const onTestChange = (e) => {
    setUserIsTest(e.target.value);
  };

  const onInfluencerChange = (e) => {
    setUserIsInfluencer(e.target.value);
  };

  const handleSaveUserInfo = () => {
    patchTestUser();
    patchUserProfiles();
  };

  useEffect(() => {
    if (patchTestUserDone) {
      notification.success({
        message: '테스트 계정 업데이트에 성공하였습니다.',
        description: '테스트 계정을 확인해 주세요.',
      });
      initializePatchTestUser();
    }
  }, [patchTestUserDone]);

  useEffect(() => {
    if (patchUserProfilesDone) {
      notification.success({
        message: '레어메이트 업데이트에 성공하였습니다.',
        description: '레어메이트 정보를 확인해 주세요.',
      });
      initializePatchUserProfiles();
    }
  }, [patchUserProfilesDone]);

  const MaskedData = useCallback(
    ({ label, type }) => {
      return (
        <>
          <Form.Item label={label} name={type}>
            <Input
              defaultValue={
                type === unmaskedType
                  ? fetchUnmaskedInfoData?.[type] ?? '-'
                  : userData?.[type] ?? '-'
              }
              disabled
            />
            <Button className="maskedBtn" onClick={() => onChangeMasked(type)}>
              🔒
            </Button>
          </Form.Item>
        </>
      );
    },
    [fetchUserDone, fetchUnmaskedInfoDone],
  );

  const BasicData = useCallback(({ label, type, value }) => {
    return (
      <>
        <Form.Item label={label} name={type}>
          <Input defaultValue={value} disabled />
        </Form.Item>
      </>
    );
  }, []);

  const socialRender = (values) => {
    return values.map((item) => {
      let source;
      let altText;

      switch (item?.social) {
        case 'HUMANSCAPE':
          source = humanscapeIcn;
          altText = 'humanscape';
          break;
        case 'KAKAO':
          source = kakaoIcn;
          altText = 'kakao';
          break;
        case 'NAVER':
          source = naverIcn;
          altText = 'naver';
          break;
        case 'APPLE':
          source = appleIcn;
          altText = 'apple';
          break;
        case 'GOOGLE':
          source = googleIcn;
          altText = 'google';
          break;
        default:
          source = '';
          altText = '';
      }

      return (
        <div>
          <img
            src={source}
            style={source ? { height: 20, marginRight: 4 } : {}}
            alt={altText}
          />
          <span>{item?.name ? `${item?.name}` : '-'}</span>
          <span>
            {item?.gender ? ` / ${listMap.genderMap[item?.gender]}` : ' / -'}
          </span>
          <span>{item?.phone ? ` / ${item?.phone}` : ' / -'}</span>
        </div>
      );
    });
  };

  return (
    <Row span={24} className="user_userInfo">
      <Col span={24}>
        <h5>
          <FileTwoTone className="userTitleIcon" />
          개인 정보
        </h5>
        <Divider plain />
        <Button
          type="primary"
          className="userInfoBtn"
          onClick={handleSaveUserInfo}
          disabled={loading}
        >
          저장
        </Button>
        <Form
          name="basic"
          form={form}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 12 },
            md: { span: 12 },
            lg: { span: 8 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 12 },
            md: { span: 12 },
            lg: { span: 16 },
          }}
        >
          {loading ? (
            <Skeleton active />
          ) : (
            <Row span={24}>
              <Col span={12}>
                <>
                  <Form.Item label="계정유형" name="socialAccounts">
                    {userData?.socialAccounts?.length > 0 ? (
                      socialRender(userData?.socialAccounts)
                    ) : (
                      <img
                        src={humanscapeIcn}
                        style={{ height: 20, marginRight: 4 }}
                        alt="humanscape"
                      />
                    )}
                  </Form.Item>
                </>
                <MaskedData label="계정" type="email" />
                <MaskedData label="이름" type="name" />
                <MaskedData label="연락처" type="phone" />
                <MaskedData label="추가 연락처" type="optionalPhone" />
                <MaskedData label="생년월일" type="birthDate" />
                <BasicData
                  label="만 나이"
                  type="age"
                  value={`${userData?.age}세`}
                />
                <BasicData
                  label="성별"
                  type="gender"
                  value={listMap.genderMap[userData?.gender]}
                />
                <BasicData
                  label="질환"
                  type="diseaseType"
                  value={userData?.diseaseType}
                />
                <Form.Item label="테스트ID">
                  <Radio.Group onChange={onTestChange} value={userIsTest}>
                    <Radio value={true}>테스트ID</Radio>
                    <Radio value={false}>실제ID</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="이 유저가 신고 당한 수">
                  <div style={{ lineHeight: '32px' }}>
                    {userData?.abuseReportingUsers
                      ? userData?.abuseReportingUsers?.length
                      : 0}
                    건
                  </div>
                  {userData?.abuseReportingUsers?.length > 0 && (
                    <TextArea
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      value={userData?.abuseReportingUsers
                        ?.map((user) => user?.email)
                        ?.join('\n')}
                      disabled
                    />
                  )}
                </Form.Item>
                <Form.Item label="이 유저가 차단한 사람">
                  <div style={{ lineHeight: '32px' }}>
                    {userData?.blockUsers ? userData?.blockUsers?.length : 0}건
                  </div>
                  {userData?.blockUsers?.length > 0 && (
                    <TextArea
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      value={userData?.blockUsers
                        ?.map((user) => user?.email)
                        ?.join('\n')}
                      disabled
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <BasicData
                  label="닉네임"
                  type="nickname"
                  value={userData?.userProfile?.nickname}
                />
                <BasicData
                  label="본인 인증"
                  type="isIdentified"
                  value={listMap.agreeMap[userData?.isIdentified]}
                />
                <BasicData
                  label="질환 인증"
                  type="isCertified"
                  value={listMap.agreeMap[userData?.isCertified]}
                />
                <BasicData
                  label="마케팅 수신"
                  type="agreementMarketing"
                  value={listMap.agreeMap[userData?.agreementMarketing]}
                />
                <BasicData
                  label="임상 제안"
                  type="clinicalTrialsNotiOn"
                  value={listMap.agreeMap[userData?.clinicalTrialsNotiOn]}
                />
                <BasicData
                  label="14세 미만"
                  type="isUnder14"
                  value={listMap.agreeMap[userData?.isUnder14]}
                />
                <BasicData
                  label="유입 경로"
                  type="funnelMainAnswer"
                  value={userData?.funnelMainAnswer}
                />
                <BasicData
                  label="유입 상세"
                  type="funnelSubAnswer"
                  value={userData?.funnelSubAnswer}
                />
                <BasicData
                  label="계정 유형"
                  type="relationship"
                  value={userData?.relationship}
                />
                <Form.Item label="레어메이트">
                  <Radio.Group
                    onChange={onInfluencerChange}
                    value={userIsInfluencer}
                  >
                    <Radio value={false}>해당 없음</Radio>
                    <Radio value={true}>
                      <img src={system} style={{ height: 20 }} alt="system" />
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <BasicData
                  label="게시글"
                  type="feedCount"
                  value={`${userData?.feedCount}개`}
                />
                <BasicData
                  label="댓글"
                  type="commentCount"
                  value={`${userData?.commentCount}개`}
                />
              </Col>
            </Row>
          )}
        </Form>
      </Col>
    </Row>
  );
};

export default UserInfo;
