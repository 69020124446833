import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useCallback,
} from 'react';
import {
  Spin,
  Form,
  Input,
  Select,
  Tag,
  Checkbox,
  DatePicker,
  Button,
  Alert,
  Row,
  Col,
  Table,
  Upload,
  notification,
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import '../../../public/css/window.css';
import qs from 'query-string';
import { useLocation } from 'react-router';
import { useSelector, useDispatch, batch, shallowEqual } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import WindowHeader from '../../components/windowHeader';
import { treatmentNewsCreators } from '../../../store/reducers/treatmentNews.reducer';
import { dictionaryCreators } from '../../../store/reducers/dictionary.reducer';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import { treatmentStudyCreators } from '../../../store/reducers/treatmentStudy.reducer';
import { noticeCreators } from '../../../store/reducers/notice.reducer';
import { dnaCreators } from '../../../store/reducers/dna.reducer';
import { eventCreators } from '../../../store/reducers/event.reducer';
import { tagCreators } from '../../../store/reducers/tag.reducer';
import { doctorProfileCreators } from '../../../store/reducers/doctorProfile.reducer';
import ElementLoading from '../../components/elementLoading';
import { useEditorImageDelete } from '../../util/useEditorImage';
import {
  fetchTreatmentNews,
  postTreatmentNews,
  patchTreatmentNews,
  deleteTreatmentNews,
} from '../../../services/treatmentNewsService';
import { useFetch, useMutate } from '../../../hooks/useRequest';
import imagesOnPage from '../../../util/imagesOnPage';
import AntTinymceInput from '../../components/antTinymceInput';
import MoveAppUrl from '../../components/moveAppUrl';
import { ALERT_MESSAGES } from '../../../assets/alert';
import { useAntFileUpload } from '../../util/useAntFileImage';
import { TOPIC_TAG_TYPE } from '../../../services/tagService';
import { rssFeedCreators } from '../../../store/reducers/rssFeed.reducer';

const ContentsWindow = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const {
    treatmentNews,
    dictionaryInfo,
    dnaInfo,
    topicInfo,
    diseaseTypesInfo,
    notices,
    dnas,
    projects,
    doctorProfile,
    rssFeed,
  } = useSelector((state) => {
    return {
      treatmentNews: state.treatmentNewsReducer.treatmentNews.data?.map(
        (treatment) => ({
          ...treatment,
          key: treatment?.id,
        }),
      ),
      dictionaryInfo: state.dictionaryReducer.dictionary.data,
      dnaInfo: state.dnaReducer.dna.data?.map((dna) => ({
        ...dna,
        krName: dna?.name,
      })),
      topicInfo: state.tagReducer.tags.data
        ?.filter((tag) => tag.type === TOPIC_TAG_TYPE)
        ?.map((check) => ({
          ...check,
          label: check?.name,
          value: check?.id,
        })),
      diseaseTypesInfo: state.tagReducer.legacyTags.data?.filter(
        (tag) => tag.type === 'diseaseType',
      ),
      notices: state.noticeReducer.notices.data,
      dnas: state.dnaReducer.dna?.data,
      projects: state.eventReducer?.event?.data?.events,
      doctorProfile: state?.doctorProfileReducer?.doctorProfile.data?.map(
        (news) => ({
          ...news,
          key: news.id,
        }),
      ),
      rssFeed: state.rssFeedReducer?.rssFeed?.data,
    };
  }, shallowEqual);

  const plainOptions = topicInfo?.map((t) => t.name) ?? [];

  const initialState = {
    title: '',
    subtitle: '',
    photos: [],
    wroteAt: moment(),
    content: '',
    selectedDictionaries: [],
    patientJourneyTypeTags: [],
    targetUserTypeTags: [],
    targetDiseaseTypeTags: [],
    topicTypeTags: [],
    shareLink: '',
    styleVersion: 2,
    hasCustomRelatedNews: false,
    hasParticipatedDoctors: false,
    thumbnailFilePath: '',
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const { TextArea } = Input;
  const { Option } = Select;
  const [form] = Form.useForm();
  const [state, setState] = useReducer(stateReducer, initialState);
  const [windowId, setWindowId] = useState('');
  const [isPost, setIsPost] = useState(false);
  const [isPatch, setIsPatch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [arrayToDelete, setArrayToDelete] = useState([]);
  const [windowClose, setWindowClose] = useState(false);
  const [treatmentNewsId, setTreatmentNewsId] = useState(null);
  const [uploadUrl, setUploadUrl] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [distinctFileInputEvent, setDistinctFileInputEvent] = useState(null);
  const fileUploadSetting = useRef(false);

  const didCancel = useRef(false);
  const uploadCancel = useRef(false);
  const isDeleteImageExist = useRef(null);
  const deleteDone = useRef(false);

  const [dataSource, setDataSource] = useState('');
  const [doctorDataSource, setDoctorDataSource] = useState('');
  const { Search } = Input;
  const [searchSource, setSearchSource] = useState('');
  const [doctorSource, setDoctorSource] = useState('');

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedDoctorRowKeys, setSelectedDoctorRowKeys] = useState([]);
  const [selectedDoctorRows, setSelectedDoctorRows] = useState([]);
  const [checkAll, setCheckAll] = React.useState(false);
  const { data: fetchTreatmentNewsData, call: callTreatmentNews } = useFetch(
    null,
    fetchTreatmentNews,
    treatmentNewsId,
  );

  const {
    publicUrl: distinctPublicUrl,
    done: distinctImageUploadDone,
    imageUpload: distinctImageUpload,
    initialize: distinctUploadInitialize,
  } = useAntFileUpload(distinctFileInputEvent);

  const postPatchData = {
    category: 'content',
    title: state.title,
    subtitle: state.subtitle,
    photos: state.photos,
    wroteAt: moment(state.wroteAt),
    content: state.content,
    dnaIds: [],
    dictionaryIds: state.selectedDictionaries
      ?.map((dictionary) => {
        return dictionaryInfo?.find((info) => info.krName === dictionary)?.id;
      })
      ?.filter(Boolean),
    tags: [
      ...[
        ...(state.patientJourneyTypeTags ?? []),
        ...(state.targetUserTypeTags ?? []),
        ...(state?.topicTypeTags?.map((topic) => {
          return topicInfo?.find((role) => role.name === topic)?.id;
        }) ?? []),
        ...(state?.targetDiseaseTypeTags?.map((disease) => {
          return diseaseTypesInfo?.find((role) => role.name === disease)?.id;
        }) ?? []),
      ],
    ],
    shareLink: state.shareLink,
    styleVersion: state.styleVersion,
    hasCustomRelatedNews: state.hasCustomRelatedNews,
    relatedNewsIds: selectedRowKeys,
    diseaseTypeIds: [],
    treatmentStudyIds: [],
    thumbnailFilePath: state.thumbnailFilePath || null,
    participatedDoctors: selectedDoctorRowKeys,
  };
  const { mutate: putTreatmentNews, done: isPosted } = useMutate(
    postTreatmentNews,
    postPatchData,
  );

  const { mutate: changeTreatmentNews, done: isPatched } = useMutate(
    patchTreatmentNews,
    treatmentNewsId,
    postPatchData,
  );

  const { mutate: eraseTreatmentNews, done: isDeleted } = useMutate(
    deleteTreatmentNews,
    treatmentNewsId,
  );

  const {
    done: editorImageDeleteDone,
    imageDelete: editorImageDelete,
  } = useEditorImageDelete(arrayToDelete);

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    dispatch(
      treatmentNewsCreators.fetchAllTreatmentNews.request({
        params: '',
      }),
    );
    dispatch(dictionaryCreators.fetchAllDictionary.request());
    dispatch(tagCreators.fetchLegacyTags.request());
    dispatch(tagCreators.fetchAllTags.request());
    dispatch(dnaCreators.fetchAllDnas.request());
    dispatch(treatmentStudyCreators.fetchAllTreatmentStudies.request());
    dispatch(noticeCreators.fetchNotices.request());
    dispatch(eventCreators.fetchAllEvents.request());
    dispatch(doctorProfileCreators.fetchAllDoctorProfiles.request());
    dispatch(rssFeedCreators.fetchAllRssFeeds.request());
    if (params.id) {
      setTreatmentNewsId(params.id);
    } else {
      setTreatmentNewsId('');
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (treatmentNewsId) callTreatmentNews();
  }, [treatmentNewsId, callTreatmentNews]);

  useEffect(() => {
    if (
      fetchTreatmentNewsData &&
      treatmentNews &&
      topicInfo &&
      doctorProfile &&
      !didCancel.current
    ) {
      setState({
        title: fetchTreatmentNewsData.title,
        subtitle: fetchTreatmentNewsData.subtitle,
        photos: fetchTreatmentNewsData.photos,
        wroteAt: fetchTreatmentNewsData.wroteAt
          ? moment(fetchTreatmentNewsData.wroteAt)
          : '',
        content: fetchTreatmentNewsData.content,
        selectedDictionaries: fetchTreatmentNewsData.dictionaries?.map(
          (dictionary) => dictionary.krName,
        ),
        targetDiseaseTypeTags: fetchTreatmentNewsData.tags
          ?.filter((tag) => tag.type === 'diseaseType')
          ?.map((disease) => disease.name),
        patientJourneyTypeTags: fetchTreatmentNewsData.tags
          ?.filter((tag) => tag.type === 'patientJourney')
          ?.map((patientJourney) => patientJourney.id),
        targetUserTypeTags: fetchTreatmentNewsData.tags
          ?.filter((tag) => tag.type === 'targetUser')
          ?.map((targetUser) => targetUser.id),
        topicTypeTags: fetchTreatmentNewsData.tags
          ?.filter((tag) => tag.type === TOPIC_TAG_TYPE)
          ?.map((topic) => topic.name),
        shareLink: fetchTreatmentNewsData.shareLink ?? '',
        styleVersion: fetchTreatmentNewsData.styleVersion,
        hasCustomRelatedNews: fetchTreatmentNewsData?.hasCustomRelatedNews,
        participatedDoctors: fetchTreatmentNewsData?.participatedDoctors,
        hasParticipatedDoctors:
          fetchTreatmentNewsData?.participatedDoctors?.length !== 0,
        thumbnailFilePath: fetchTreatmentNewsData?.thumbnailFilePath,
      });
      setSelectedRowKeys(
        fetchTreatmentNewsData?.relationNewsArr?.map(
          (relationNew) => relationNew.id,
        ),
      );
      setCheckAll(
        fetchTreatmentNewsData.tags?.filter(
          (tag) => tag.type === TOPIC_TAG_TYPE,
        )?.length === plainOptions?.length,
      );
      setSelectedRows(
        fetchTreatmentNewsData?.relationNewsArr?.map((news) => {
          return treatmentNews?.find((role) => role.id === news.id);
        }),
      );
      setSearchSource(
        fetchTreatmentNewsData?.relationNewsArr?.map((news) => {
          return treatmentNews?.find((role) => role.id === news.id);
        }),
      );
      setDoctorSource(
        fetchTreatmentNewsData?.participatedDoctorsProfiles?.map((news) => ({
          ...news,
          key: news.id,
        })),
      );
      setSelectedDoctorRowKeys(fetchTreatmentNewsData?.participatedDoctors);
      setSelectedDoctorRows(
        fetchTreatmentNewsData?.relationNewsArr?.map((news) => {
          return treatmentNews?.find((role) => role.id === news.id);
        }),
      );
      setSelectedDoctorRows(
        fetchTreatmentNewsData?.participatedDoctors?.map((news) => {
          return doctorProfile?.find((role) => role.id === news);
        }),
      );
      didCancel.current = true;
      setIsLoading(false);
      setDataSource(treatmentNews);
      setDoctorDataSource(doctorProfile);
    }
    if (
      treatmentNewsId === '' &&
      !didCancel.current &&
      treatmentNews &&
      topicInfo
    ) {
      didCancel.current = true;
      setIsLoading(false);
      setDataSource(treatmentNews);
      setDoctorDataSource(doctorProfile);
    }
  }, [
    treatmentNewsId,
    fetchTreatmentNewsData,
    treatmentNews,
    dictionaryInfo,
    dnaInfo,
    topicInfo,
    doctorProfile,
  ]);

  useEffect(() => {
    if (arrayToDelete.length > 0 && !deleteDone.current) {
      editorImageDelete();
      setWindowClose(true);
      deleteDone.current = true;
    }
  }, [arrayToDelete, editorImageDelete, deleteDone]);

  useEffect(() => {
    let message = '';
    if (isPosted) {
      message = ALERT_MESSAGES.CREATE;
    }
    if (isPatched) {
      message = ALERT_MESSAGES.UPDATE;
    }
    if (isDeleted) {
      message = ALERT_MESSAGES.DELETE;
    }
    if (isDeleteImageExist.current) {
      if (arrayToDelete.length === 0 && windowClose) {
        if (isPosted || isPatched || isDeleted) {
          setWindowClose(false);
          alert(message);
          window.close();
        }
      } else if (
        arrayToDelete.length > 0 &&
        windowClose &&
        editorImageDeleteDone
      ) {
        if (isPosted || isPatched || isDeleted) {
          setWindowClose(false);
          alert(message);
          window.close();
          setArrayToDelete([]);
        }
      }
    } else if (isPosted || isPatched || isDeleted) {
      setWindowClose(false);
      alert(message);
      window.close();
    }
  }, [
    windowClose,
    arrayToDelete,
    isDeleted,
    isPosted,
    isPatched,
    editorImageDeleteDone,
  ]);

  const renewalImages = (document) => {
    const images = imagesOnPage(document);
    setState({ photos: images.map((image) => ({ uri: image })) });
  };

  useEffect(() => {
    if (uploadUrl && !state.photos.map(({ uri }) => uri).includes(uploadUrl)) {
      const newPhotos = [...state.photos, { uri: uploadUrl }];
      batch(() => {
        setState({ photos: newPhotos });
        setUploadUrl(null);
      });
    }
  }, [uploadUrl, state.photos]);

  useEffect(() => {
    form.setFieldsValue({
      selectedDictionaries: state?.selectedDictionaries,
    });
  }, [state?.selectedDictionaries]);

  const columns = [
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '질환',
      dataIndex: 'diseaseTypes',
      key: 'diseaseTypes',
      width: '250px',
      render: (text) => (
        <div
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: 250,
          }}
        >
          {text}
        </div>
      ),
    },
  ];

  const doctorColumns = [
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '병원명',
      dataIndex: 'hospital',
      key: 'hospital',
    },
    {
      title: '소속과',
      dataIndex: 'specialty',
      key: 'specialty',
    },
  ];

  const changeState = useCallback((e) => {
    setState({
      content: e,
    });
  }, []);

  const onDateChange = (date, dateString) => {
    setState({
      wroteAt: dateString,
    });
  };

  const onDiseaseChange = (diseases) => {
    setState({
      targetDiseaseTypeTags: diseases,
    });
  };

  const onRelatedTreatmentChange = (e) => {
    setState({
      hasCustomRelatedNews: e.target.checked,
    });
  };

  const onRelatedDoctorChange = (e) => {
    setState({
      hasParticipatedDoctors: e.target.checked,
    });
  };

  const onSearch = (e) => {
    if (e === '') {
      setSearchSource(selectedRows);
    } else {
      const filteredDatas = dataSource?.filter((entry) =>
        Object?.values(entry).toString().includes(e),
      );

      setSearchSource(
        [...filteredDatas, ...selectedRows].filter((item1, idx1) => {
          return (
            [...filteredDatas, ...selectedRows].findIndex((item2, idx2) => {
              return item1.id === item2.id;
            }) === idx1
          );
        }),
      );
    }
  };

  const onDoctorSearch = (e) => {
    if (e === '') {
      setDoctorSource(selectedDoctorRows);
    } else {
      const filteredDatas = doctorDataSource?.filter((entry) =>
        Object?.values(entry).toString().includes(e),
      );

      setDoctorSource(
        [...filteredDatas, ...selectedDoctorRows].filter((item1, idx1) => {
          return (
            [...filteredDatas, ...selectedDoctorRows].findIndex(
              (item2, idx2) => {
                return item1.id === item2.id;
              },
            ) === idx1
          );
        }),
      );
    }
  };

  const onFinish = (values) => {
    setShowAlert(false);
    setState({
      title: values.title,
      subtitle: values.subtitle,
      shareLink: values.shareLink,
    });
    if (treatmentNewsId) {
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
      }
    } else if (window.confirm('생성하시겠습니까?')) {
      setIsPost(true);
    }
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      setWindowClose(false);
      setArrayToDelete([]);
      window.close();
    }
  };

  const onDelete = () => {
    if (window.confirm('삭제하시겠습니까?')) {
      eraseTreatmentNews();
    }
  };

  useEffect(() => {
    if (isPatch) {
      changeTreatmentNews();
      setIsPatch(false);
    }
    if (isPost) {
      putTreatmentNews();
      setIsPost(false);
    }
  }, [isPatch, isPost]);

  const rowSelection = {
    defaultSelectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKey, selectedRow) => {
      if (selectedRowKey?.length > 20) {
        alert('같이 보면 좋아요는 20개 이하로 선택해주세요.');
      } else {
        setSelectedRowKeys(selectedRowKey);
        setSelectedRows(selectedRow);
      }
    },
  };

  const rowDoctorSelection = {
    defaultSelectedRowKeys: selectedDoctorRowKeys,
    onChange: (selectedRowKey, selectedRow) => {
      setSelectedDoctorRowKeys(selectedRowKey);
      setSelectedDoctorRows(selectedRow);
    },
  };

  const onTopicChange = (list) => {
    setState({
      topicTypeTags: list,
    });
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e) => {
    setState({
      topicTypeTags: e.target.checked ? plainOptions : [],
    });
    setCheckAll(e.target.checked);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({
        message: 'JPG/PNG만 업로드 가능합니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      notification.error({
        message: '이미지가 10MB보다 큽니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
    }
    return isJpgOrPng && isLt10M;
  };

  const uploadThumbImg = async (e) => {
    if (e !== undefined) {
      setDistinctFileInputEvent(e);
      uploadCancel.current = true;
      setLoading(true);
    }
  };

  const noop = () => {};

  const deleteThumbImg = () => {
    setState({
      thumbnailFilePath: '',
    });
    notification.success({
      message: '이미지를 삭제하였습니다.',
      description: '이미지를 다시 업로드해주세요.',
    });
  };

  const uploadProps = {
    customRequest: noop,
    onChange: uploadThumbImg,
    onPreview: noop,
  };

  useEffect(() => {
    if (distinctFileInputEvent && uploadCancel.current) {
      distinctImageUpload();
      uploadCancel.current = false;
      fileUploadSetting.current = true;
    }
  }, [distinctFileInputEvent, distinctImageUpload]);

  useEffect(() => {
    if (distinctImageUploadDone && distinctPublicUrl) {
      notification.success({
        message: '이미지 업로드에 성공하였습니다.',
        description: '썸네일 등록에 성공하였습니다.',
      });
      setState({ thumbnailFilePath: distinctPublicUrl });
      distinctUploadInitialize();
      setLoading(false);
    }
  }, [distinctImageUploadDone, distinctPublicUrl, distinctUploadInitialize]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  if (isLoading) return <ElementLoading type="콘텐츠" />;
  return (
    <>
      <WindowHeader title="정보 > 콘텐츠" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
              title: state.title,
              subtitle: state.subtitle,
              wroteAt: state.wroteAt,
              selectedDictionaries: state.selectedDictionaries,
              targetDiseaseTypeTags: state.targetDiseaseTypeTags,
              shareLink: state.shareLink,
              styleVersion: state.styleVersion,
            }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
          >
            <Form.Item label="카테고리">
              <Input prefix="콘텐츠" disabled />
            </Form.Item>
            <Form.Item
              label="제목"
              name="title"
              rules={[
                {
                  required: true,
                  message: '제목을 확인해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="부제목" name="subtitle">
              <Input />
            </Form.Item>
            <Form.Item
              label="등록일"
              name="wroteAt"
              rules={[
                {
                  required: true,
                  message: '등록일을 확인해주세요.',
                },
              ]}
            >
              <DatePicker onChange={onDateChange} />
            </Form.Item>
            <Form.Item label="썸네일" name="thumbnailFilePath">
              <Upload
                {...uploadProps}
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUpload}
              >
                {state?.thumbnailFilePath ? (
                  <img
                    src={state?.thumbnailFilePath}
                    alt="avatar"
                    style={{
                      width: '100%',
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
              {state?.thumbnailFilePath && (
                <Button
                  type="primary"
                  style={{ width: 104 }}
                  danger
                  onClick={deleteThumbImg}
                >
                  썸네일 삭제
                </Button>
              )}
            </Form.Item>
            <MoveAppUrl
              type="contents"
              treatmentNews={treatmentNews}
              notices={notices}
              dnas={dnas}
              projects={projects}
              rssFeed={rssFeed}
            />
            <Form.Item label="내용" name="content" className="treatmentContent">
              <LoadingOverlay
                active={imageLoading}
                spinner={<Spin />}
                text={<p>이미지를 업로드 하는 중</p>}
              >
                <AntTinymceInput
                  content={state.content}
                  setContent={changeState}
                  setUploadUrl={setUploadUrl}
                  onBlur={renewalImages}
                  setImageLoading={setImageLoading}
                  contentLinkData={{
                    dictionaryInfo,
                    setState,
                  }}
                />
              </LoadingOverlay>
              <TextArea rows={10} value={state?.content} />
            </Form.Item>
            <hr />
            <Form.Item
              name="selectedDictionaries"
              label="본문링크 용어"
              hasFeedback
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="본문 링크 적용버튼을 클릭시 자동 선택"
              >
                {dictionaryInfo?.map((dictionary) => (
                  <Option key={dictionary.krName}>{dictionary.krName}</Option>
                ))}
              </Select>
            </Form.Item>
            <hr />
            {plainOptions && (
              <Form.Item label="주제별 태그" name="topicTypeTags">
                <Checkbox onChange={onCheckAllChange} checked={checkAll}>
                  전체
                </Checkbox>
                <Checkbox.Group
                  options={plainOptions}
                  value={state?.topicTypeTags}
                  onChange={onTopicChange}
                />
              </Form.Item>
            )}
            <Form.Item
              label="질환 태그"
              name="targetDiseaseTypeTags"
              hasFeedback
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="질환 태그를 선택해주세요"
                onChange={onDiseaseChange}
              >
                {diseaseTypesInfo?.map((diseaseType) => (
                  <Option
                    key={diseaseType?.name}
                    id={diseaseType?.name}
                    value={diseaseType?.name}
                  >
                    {diseaseType.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <hr />

            <Form.Item label="공유 링크" name="shareLink">
              <Input placeholder="미입력 시 자동으로 생성됩니다" />
            </Form.Item>
            <hr />

            <Form.Item label="같이 보면 좋아요" name="hasCustomRelatedNews">
              <Checkbox
                onChange={onRelatedTreatmentChange}
                checked={state?.hasCustomRelatedNews}
              >
                직접 선택하기 (직접 선택을 체크하지 않으면 관련 정보를 자동으로
                불러옵니다.)
              </Checkbox>
            </Form.Item>
            {state?.hasCustomRelatedNews && (
              <Col span={20} offset={4} style={{ marginBottom: 30 }}>
                {treatmentNews && (
                  <>
                    <Row>
                      <Col span={24}>
                        <Search
                          placeholder="콘텐츠, 질환 정보 검색어를 입력하세요"
                          allowClear
                          className="searchStyle"
                          style={{ float: 'left' }}
                          onSearch={onSearch}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} style={{ marginBottom: 16 }}>
                        {selectedRows?.map((row, i) => (
                          <Tag color="blue">
                            {i + 1}순위 = {row?.title}
                          </Tag>
                        ))}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Table
                          rowSelection={rowSelection}
                          columns={columns}
                          dataSource={searchSource}
                          bordered
                          size="small"
                          pagination={false}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            )}

            <Form.Item label="협업 의료진" name="hasParticipatedDoctors">
              <Checkbox
                onChange={onRelatedDoctorChange}
                checked={state?.hasParticipatedDoctors}
              >
                협업 의료진 선택하기
              </Checkbox>
            </Form.Item>
            {state?.hasParticipatedDoctors && (
              <Col span={20} offset={4} style={{ marginBottom: 30 }}>
                {doctorProfile && (
                  <>
                    <Row>
                      <Col span={24}>
                        <Search
                          placeholder="의료진 검색어를 입력하세요"
                          allowClear
                          className="searchStyle"
                          style={{ float: 'left' }}
                          onSearch={onDoctorSearch}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} style={{ marginBottom: 16 }}>
                        {selectedDoctorRows?.map((row, i) => (
                          <Tag color="blue">
                            {i + 1}순위 = {row?.name}
                          </Tag>
                        ))}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Table
                          rowSelection={rowDoctorSelection}
                          columns={doctorColumns}
                          dataSource={doctorSource}
                          bordered
                          size="small"
                          pagination={false}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            )}

            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
              {treatmentNewsId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default ContentsWindow;
