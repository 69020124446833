const PAGE_URL = {
  LOGIN: 'login',
  MAIN: 'main',
  ADMIN: 'admin',
  ADMIN_WINDOW: 'adminDetail',
  ADMIN_SETTING: 'adminSetting',
  USER: 'user',
  USER_WINDOW: 'userDetail',
  USER_WITHDRAWAL: 'userWithdrawal',
  DNA_COUNSELING: 'dnaCounselingRequest',
  DNA_COUNSELING_SETTING: 'dnaCounselingSetting',
  DNA_COUNSELING_DETAIL: 'dnaCounselingRequestDetail',
  MUTATION_DNA_REPORT: 'mutationDnaReport',
  MUTATION_DNA_REPORT_WINDOW: 'mutationDnaReportDetail',
  MUTATION_DNA_REPORT_REGISTER_REQUEST: 'mutationDnaReportRegisterRequest',
  HOSPITALEXAMINATION_RECORDS_REQUEST: 'hospitalExaminationRecordsRequest',
  DISEASETYPECERTIFICATION_REQUEST: 'diseaseTypeCertificationsRequest',
  MUTATION_DNA_REPORT_REGISTER_REQUEST_DETAIL:
    'mutationDnaReportRegisterRequestDetail',
  MUTATION_DNA: 'mutationDna',
  MUTATION_DNA_WINDOW: 'mutationDnaDetail',
  TREATMENT_STUDY: 'treatmentStudy',
  TREATMENT_STUDY_WINDOW: 'treatmentStudyDetail',
  TREATMENT_STUDY_TIMELINE: 'treatmentStudyTimeline',
  TREATMENT_STUDY_TIMELINE_WINDOW: 'treatmentStudyTimelineDetail',
  TREATMENT_NEWS_WINDOW: 'treatmentNewsDetail',
  DNA: 'dna',
  DNA_WINDOW: 'dnaDetail',
  DICTIONARY: 'dictionary',
  DICTIONARY_WINDOW: 'dictionaryDetail',
  DISEASE_TYPE: 'diseaseType',
  DISEASE_TYPE_WINDOW: 'diseaseTypeDetail',
  DISEASE_TYPE_CLINICAL_TRIAL: 'diseaseTypeClinicalTrial',
  CLINICAL_TRIAL: 'clinicalTrial',
  CLINICAL_TRIAL_WINDOW: 'clinicalTrialDetail',
  CLINICALTRIAL_COUNSELING_SETTING: 'clinicalTrialCounselingSetting',
  // DNA_ANALYSIS_STATS: 'dnaAnalysisStats',
  // DNA_ANALYSIS_STATS_WINDOW: 'dnaAnalysisStatsDetail',
  HEALTH_PROFILE: 'healthProfile',
  HEALTH_PROFILE_USERS: 'healthProfileUsers',
  HEALTH_PROFILE_WINDOW: 'healthProfileDetail',
  HEALTH_PROFILE_QUESTION_WINDOW: 'healthProfileQuestionDetail',
  HEALTH_PROFILE_OPTION_WINDOW: 'healthProfileOptionDetail',
  ENTRY_MODAL: 'entryModal',
  ENTRY_MODAL_WINDOW: 'entryModalDetail',
  NOTICE: 'notice',
  NOTICE_WINDOW: 'noticeDetail',
  NOTIFICATION: 'notification',
  INDIVIDUAL_NOTIFICATION: 'individualNotification',
  DASHBOARD: 'dashboard',

  DASHBOARD_DATA: 'dashboardData',
  HISTORY: 'history',
  CONVERSION: 'conversion',
  CONVERSION_SETTING: 'conversionSetting',
  HOSPITAL_RECORD: 'hospitalRecord',
  EVENT: 'event',
  EVENT_PARTICIPANTS: 'eventParticipants',
  HOSPITAL_EXAMINATION_WINDOW: 'hospitalExaminationDetail',
  USER_HOSPITAL_EXAMINATION_WINDOW: 'userHospitalExaminationDetail',
  USER_DISEASE_TYPE_CERTIFICATION_WINDOW: 'userdiseaseTypeCertificationDetail',
  EVENT_WINDOW: 'eventDetail',
  SUPPORT_VERSION: 'supportVersion',
  SUPPORT_VERSION_WINDOW: 'supportVersionDetail',
  POPUP: 'popup',
  POPUP_WINDOW: 'popupDetail',
  CLINICALTRIAL_COUNSELING: 'clinicalTrialCounseling',
  CLINICALTRIAL_COUNSELING_DETAIL: 'clinicalTrialCounselingDetail',
  RESEARCH: 'research',
  RESEARCH_WINDOW: 'researchDetail',
  GENE_RESEARCH: 'geneResearch',
  GENE_RESEARCH_DETAIL: 'geneResearchDetail',
  IPALLOW: 'ipAllow',
  IPALLOW_WINDOW: 'ipAllowDetail',
  CONTENTS_WEB_VIEW: 'contentsWebView',
  CONTENTS: 'contents',
  CONTENTS_WINDOW: 'contentsDetail',
  CONTENTS_RESERVATION_WINDOW: 'contentsReservationDetail',
  DISEASEINFO_WINDOW: 'DiseaseInfoDetail',
  DOCTORPROFILE: 'doctorProfile',
  DOCTORPROFILE_WINDOW: 'doctorProfileDetail',
  REHABILITATION: 'rehabilitation',
  REHABILITATION_WINDOW: 'rehabilitationDetail',
  REHABILITATION_DETAIL_WINDOW: 'rehabilitationDetailDetail',
  PATIENT_GROUP: 'patientGroup',
  PATIENT_GROUP_USER: 'patientGroupUser',
  PATIENT_GROUP_WINDOW: 'patientGroupDetail',
  REMOTE_CONFIG: 'remoteConfig',
  REMOTE_CONFIG_WINDOW: 'remoteConfigDetail',
  RSS_FEED: 'rssFeed',
  RSS_FEED_WINDOW: 'rssFeedDetail',
  TOPIC_TAG: 'tag',
  TOPIC_TAG_WINDOW: 'tagDetail',
  TRANSLATE_WORD: 'translateWord',
  TRANSLATE_WORD_WINDOW: 'translateWordDetail',
  DISEASEINFO: 'diseaseInfo',
};

export default PAGE_URL;
