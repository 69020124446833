import querystring from 'querystring';

export const SUCCESS = 0;
export const INVALID_PARAMETER = 1;
export const UNAUTHORIZED = 2;
export const CONFLICT = 3;
export const NO_MATCHING_DATA_FOUND = 4;
export const TOKEN_HAS_BEEN_EXPIRED = 5;

export const DEFAULT_WINDOW_SIZE = {
  width: '1024px',
  height: '800px',
};

export const NEW = 'new::';

function removeUndefinedProperty(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v !== undefined),
  );
}

export function getWindowURL({ dataType, id, subId, extraId }) {
  const query = removeUndefinedProperty({
    ...(id.startsWith(NEW) ? { new: id } : { id }),
    subId,
    extraId,
  });

  const stringifiedQuery = querystring.stringify(query);

  return `/${dataType}Detail?${stringifiedQuery}`;
}
